<template>
  <div class="service">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <service-item
            v-for="(item, index) in services"
            :key="index"
            :item="item"
            :index="index"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import ServiceItem from "./ServiceItem.vue";
export default {
  computed: {
    ...mapState({
      services: (state) => state.services.services,
    }),
  },
  components: {
    ServiceItem,
  },
};
</script>