<template>
  <div class="services">
    <page-cover :cover="cover"  v-if="cover" />
    <ServiceContent />
  </div>
</template>
<script>
import PageCover from "@/components/shared/PageCover.vue";
import ServiceContent from "@/components/services/ServiceContent.vue";
import { mapGetters } from "vuex";
export default {
  name: "about",
  computed: {
    ...mapGetters({
      cover: "pageCover/getServices",
    }),
  },
  created() {
    this.$store.dispatch("services/getServices");
  },
  components: {
    PageCover,
    ServiceContent,
  },
};
</script>