<template>
  <div class="service-item">
    <div class="row">
      <div class="col-lg-6 d-none d-lg-block" v-if="index % 2 == 0">
        <div class="service-item__image">
          <img :src="item.image" />
        </div>
      </div>
      <div class="col-lg-6">
        <div
          class="service-item__content team-item"
          :class="{ 'service-item__content--right team-item--dark': index % 2 == 0 }"
        >
          <div class='team-item__inner h-100 pt-10'>
            <div class="service-item__icon">
              <img :src="icons[index]" />
            </div>
            <div class="service-item__title">
              {{ item.title }}
            </div>
            <div class="service-item__text" v-html="item.description"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 d-none d-lg-block" v-if="index % 2 != 0">
        <div class="service-item__image">
          <img :src="item.image" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      icons: [
        require("@/assets/images/icons/cube.svg"),
        require("@/assets/images/icons/way.svg"),
        require("@/assets/images/icons/contract.svg"),
        require("@/assets/images/icons/hand.svg"),
      ],
    };
  },
};
</script>